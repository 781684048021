// THIS FILE IS GENERATED AUTOMATICALLY DURING BUILD
// SHOULD NOT BE EDITED MANUALLY
import { version } from '../../../../../package.json';
export const versionInfo = {
  buildTime:'2024-11-11T10:05:26+01:00',
  gitHash:'92590350f',
  branchName:'gpd',
  tag:'v1.3.77_gpd',
  version:version
};
